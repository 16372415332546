<template>
  <div
    style="
      background-color: #171726;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
    "
  >
    <nav-bar
      class="title_top"
      :icon="'img/backw.png'"
      :textColor="'#ffffff'"
      :backColor="'none'"
    />
    <div>
      <div class="tap_item" v-if="walletInfo.is_bind_phone == 1">
        <span>绑定手机号</span>
        <span>{{ walletInfo.phone }}</span>
      </div>
      <div class="tap_item" v-else @click="toBindPhone">
        <span>绑定手机号</span>
        <img src="img/myIncome/next.png" alt="" />
      </div>
      <div class="tap_item" @click="toBindAlipay">
        <span>支付宝管理</span>
        <img src="img/myIncome/next.png" alt="" />
      </div>
      <div class="tap_item" @click="toBindBankCard">
        <span>银行卡管理</span>
        <img src="img/myIncome/next.png" alt="" />
      </div>
      <div class="tap_item" @click="toSetLevelPassword">
        <span>设置二级密码</span>
        <img src="img/myIncome/next.png" alt="" />
      </div>
      <div class="tap_item">
        <span>二级安全密码</span>
        <van-switch
          v-model="checked"
          active-color="#07c160"
          inactive-color="#999999"
          @change="switchToggle"
        />
      </div>
      <div class="btn_item" @click="logout">退出登录</div>
    </div>
    <div class="mask" v-if="showPasswordModal">
      <div
        class="modal_content"
        style="background-image: url('img/myIncome/modal_bg.png')"
      >
        <div class="modal_title">密码验证</div>
        <div class="input_item_password_box">
          <input
            v-model="password"
            class="input_item_password"
            type="number"
            placeholder="请输入二级安全密码"
          />
        </div>
        <div class="btns_box">
          <div class="btn_item_cancel" @click="showPasswordModal = false">
            取消
          </div>
          <div class="btn_item_enter" @click="turnSecondPassword">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NavBar from "@/components/navBar.vue";
import { walletIndex, turnSecondPassword } from "@/api/wallet";
export default {
  name: "infoManage",
  components: {
    NavBar,
  },
  data() {
    return {
      checked: false,
      walletInfo: {},
      password: "",
      showPasswordModal: false,
    };
  },
  created() {
    this.getWalletIndex();
  },
  methods: {
    getWalletIndex() {
      walletIndex({}).then((res) => {
        console.log(res);
        this.walletInfo = res.data;
        if (this.walletInfo.is_verify == 1) {
          this.checked = true;
        } else {
          this.checked = false;
        }
      });
    },
    toBindPhone() {
      this.$router.push({
        path: "/bindPhone",
      });
    },
    toBindAlipay() {
      if (this.walletInfo.is_bind_alipay == 1) {
        this.$router.push({
          path: "/bindAlipay",
        });
      } else {
        this.$router.push({
          path: "/bindUserInfo",
        });
      }
    },
    toBindBankCard() {
      if (this.walletInfo.is_bind_alipay == 1) {
        this.$router.push({
          path: "/bindBankCard",
        });
      } else {
        this.$router.push({
          path: "/bindUserInfo",
        });
      }
    },
    toSetLevelPassword() {
      this.$router.push({
        path: "/setLevelPassword",
      });
    },
    switchToggle(e) {
      console.log(e);
      if (!e) {
        this.showPasswordModal = true;
      }else{
        if(this.walletInfo.is_set_password==0){
          this.$toast.fail('请先设置二级密码')
          this.checked = false
        }else{
          this.turnSecondPassword()
        }
      }
    },
    turnSecondPassword(){
        let params={
            password:this.password
        }
        turnSecondPassword(params).then(res=>{
            if(res.code==10000){
                this.showPasswordModal = false;
                this.getWalletIndex();  
            }
        },
        (error)=>{
            this.$toast.fail(error); 
        })
    },
    logout(){
      localStorage.removeItem('access_token');
      this.$router.push({
          path: "/phoneLogin",
        });
    }
  },
};
</script>
<style scoped>
.tap_item {
  padding: 0 24px 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 114px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  background-color: rgba(255, 255, 255, 0.04);
}

.tap_item span {
  color: #ffffff;
  font-size: 30px;
}

.tap_item img {
  width: 25px;
  height: 25px;
}
.mask {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
}

.modal_content {
  width: 640px;
  height: 500px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 540px auto 0;
  overflow: hidden;
  z-index: 999;
}

.modal_title {
  text-align: center;
  font-size: 34px;
  color: #111111;
  margin-top: 40px;
}

.input_item_password_box {
  padding: 0 40px 0 40px;
  height: 108px;
}

.input_item_password {
  border: none;
  display: block;
  height: 100%;
  width: 100%;
  font-size: 30px;
  background: rgba(216, 216, 216, 1);
  color: #111111;
  border-radius: 20px;
  margin-top: 100px;
  text-indent: 40px;
}

.btns_box {
  height: 98px;
  display: flex;
  justify-content: space-between;
  margin-top: 64px;
  padding: 0 40px 0 40px;
}
.btn_item {
  width: 95%;
  height: 80px;
  background-color: #f56c6c;
  color: #ffffff;
  line-height: 80px;
  text-align: center;
  font-size: 28px;
  border-radius: 40px;
  margin: 40px auto 0;
}
.btn_item_cancel {
  width: 236px;
  height: 98px;
  color: #111111;
  background-color: #d8d8d8;
  line-height: 98px;
  text-align: center;
  font-size: 30px;
  border-radius: 20px;
}

.btn_item_enter {
  width: 236px;
  height: 98px;
  color: #111111;
  background : linear-gradient(to bottom, #02C3FD 0, #61FEAD 100%);
  line-height: 98px;
  text-align: center;
  font-size: 30px;
  border-radius: 20px;
}
</style>